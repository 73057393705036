import React from "react";
import circle from "../../assets/image/Why choose reso image 400X375 - Copy.jpg";
import { Autoplay, EffectFade } from "swiper/modules";
import {
  LandingSliderImage1,
  LandingSliderImage2,
  LandingSliderImage3,
  LandingSliderImage4,
  atom,
} from "../../data/ImageData";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";

const LandingSlider = () => {
  return (
    <div className="lg:-translate-y-14 lg:-translate-x-7 translate-x-0 translate-y-0">
      {" "}
      <div
        className="h-12 w-12 relative z-[5000] left-8 top-8 rotate_3d "
        style={{
          background: `url(${atom})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div>
        {/* <img className="w-80 h-80 sm:w-80 sm:h-80 lg:w-96 lg:h-96 rounded-full bg-red-100 mb-10 lg:mb-0" src={circle} /> */}

        <Swiper
          effect="fade"
          modules={[Autoplay, EffectFade]}
          autoplay={{ delay: 2000 }}
          className=" w-80 h-80 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] mb-10 lg:mb-0"
        >
          <SwiperSlide>
            <img
              className="w-80 h-80 sm:w-80 sm:h-80 lg:w-[450px] object-cover bg-no-repeat lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0"
              src={LandingSliderImage1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="w-80 h-80 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0"
              src={LandingSliderImage2}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="w-80 h-80 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0"
              src={LandingSliderImage3}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="w-80 h-80 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0"
              src={LandingSliderImage4}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default LandingSlider;
