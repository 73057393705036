import React from "react";
import BranchLocation from "../components/BranchLocation";
import { ResonanceLogoBlack } from "../data/ImageData";
import icon1 from "../assets/image/col-icon-1.png";
import icon2 from "../assets/image/col-icon-2.png";
import icon3 from "../assets/image/col-icon-3.png";
import icon4 from "../assets/image/col-icon-4.png";
import icon5 from "../assets/image/icon-envelope.png";
import icon6 from "../assets/image/coaching.png";

const TopNeetCoachingIndore = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted!");
  };

  const openWin = () => {
    console.log("Button clicked!");
  };

  return (
    <>
      <section className="pt-28 pb-28 relative bg-gray-100 px-4">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            {/* Form Section */}
            <div className="w-full lg:w-1/2 xl:w-5/12 bg-white p-8 rounded-lg shadow-lg relative">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <h3 className="text-2xl font-semibold mb-5">
                  We'll be in touch as soon as possible.
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                      required
                      placeholder="Full Name"
                      id="fullname"
                    />
                  </div>
                  <div>
                    <input
                      type="tel"
                      className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                      required
                      placeholder="Number"
                      id="number"
                      pattern="[0-9]{10}"
                      title="Please enter a 10-digit phone number"
                    />
                  </div>
                  <div>
                    <input
                      type="tel"
                      className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Alternate No."
                      id="altnumber"
                      pattern="[0-9]{10}"
                      title="Please enter a 10-digit phone number"
                    />
                  </div>
                  <div>
                    <select
                      className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                      id="courses"
                      required
                    >
                      <option value="">-Target Examination-</option>
                      <option value="JEE(Main + Advanced)">
                        JEE(Main + Advanced)
                      </option>
                      <option value="JEE(Main)">JEE(Main)</option>
                      <option value="NEET">NEET</option>
                      <option value="Foundation Course">
                        Foundation Course
                      </option>
                      <option value="CUET">CUET</option>
                    </select>
                  </div>
                </div>
                <textarea
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
                  placeholder="Tell us more about your query."
                  id="message"
                  style={{ height: "120px" }}
                ></textarea>
                <button
                  type="submit"
                  onClick={openWin}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg w-full transition"
                >
                  Submit
                </button>
              </form>
            </div>

            <div className="w-full lg:w-1/2 xl:w-5/12 mt-10 lg:mt-0">
              <div className="text-center lg:text-left">
                <div className="mb-5 ">
                  <img
                    src={ResonanceLogoBlack}
                    alt="Resonance logo"
                    className="mx-auto lg:mx-0 w-48"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Let Resonance Guide You to <br />
                  <span className="text-green-600">
                    A Better &amp; Brighter Future
                  </span>
                </h3>
                <h4 className="text-lg font-medium text-gray-800 mb-3">
                  Vision
                </h4>
                <ul className="list-disc list-inside text-gray-600 mb-4">
                  <li>Excellence in Career Education</li>
                </ul>
                <h4 className="text-lg font-medium text-gray-800 mb-3">
                  Mission
                </h4>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Practice, Persistence, and Performance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto">
          {/* About Us Section */}
          <div className="flex justify-center">
            <div className="w-full">
              <h2 className="text-center text-2xl font-semibold">About Us</h2>
            </div>
          </div>

          {/* Image Section */}
          <div className="container mx-auto mb-5">
            <div className="flex flex-wrap -mx-4">
              {/* Card 1 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon1}
                      alt="Top-Notch Faculty"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">Top-Notch Faculty</h4>
                  <p className="text-sm text-gray-600">
                    Learn from experienced and highly qualified faculties who
                    have a deep understanding of the JEE/NEET syllabus and who
                    have produced top rankers. Benefit from their expertise,
                    guidance, and personalized attention throughout your
                    learning journey.
                  </p>
                </div>
              </div>

              {/* Card 2 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon2}
                      alt="Comprehensive Curriculum"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">
                    Comprehensive Curriculum
                  </h4>
                  <p className="text-sm text-gray-600">
                    Our well-structured curriculum covers all the essential
                    topics, ensuring you have a solid foundation in the
                    subjects. We focus on exam-oriented preparation, helping you
                    master the necessary skills and techniques to tackle the JEE
                    and NEET effectively.
                  </p>
                </div>
              </div>

              {/* Card 3 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon3}
                      alt="Interactive Learning"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">
                    Interactive Learning
                  </h4>
                  <p className="text-sm text-gray-600">
                    Engage in interactive and collaborative learning sessions
                    that promote active participation. Our classrooms are
                    equipped with modern teaching aids and technology, enabling
                    you to grasp complex concepts easily and engage in
                    productive discussions with your peers.
                  </p>
                </div>
              </div>

              {/* Card 4 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon4}
                      alt="Study Material and Resources"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">
                    Study Material and Resources
                  </h4>
                  <p className="text-sm text-gray-600">
                    Gain access to our comprehensive study materials, including
                    well-researched notes, practice questions, and previous
                    years' papers. Additionally, we provide online resources and
                    digital learning tools to enhance your study experience.
                  </p>
                </div>
              </div>

              {/* Card 5 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon5}
                      alt="Mentoring and Counseling"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">
                    Mentoring and Counseling
                  </h4>
                  <p className="text-sm text-gray-600">
                    Our coaching program goes beyond academics. We provide
                    mentoring and counseling sessions to help you overcome
                    exam-related stress, manage your time effectively, and
                    maintain a healthy work-life balance. Our mentors will be
                    your constant support system throughout your exam journey.
                  </p>
                </div>
              </div>

              {/* Card 6 */}
              <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mt-3">
                <div className="bg-white shadow-lg p-5 rounded-lg">
                  <div className="flex justify-center mb-4">
                    <img
                      className="colored-image w-16"
                      src={icon6}
                      alt="Success Stories"
                    />
                  </div>
                  <h4 className="text-lg font-bold mb-2">Success Stories</h4>
                  <p className="text-sm text-gray-600">
                    Join the ranks of our successful alumni who have achieved
                    remarkable results in JEE and NEET. Their stories serve as a
                    testament to the effectiveness of our coaching program and
                    inspire you to strive for excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BranchLocation />
    </>
  );
};

export default TopNeetCoachingIndore;
