import React from "react";
import poster from "../assets/image/carouselImages/website Banner-third.jpg";
import JEEResultSection from "../components/sections/JEEResultSection";
import NEETResultSection from "../components/sections/NEETResultSection";
import Slider from "../components/Slider";
import { mahi_mittal, parth_bagle, vedika_jaju } from "../data/ImageData";
function ResultPage() {
  return (
    <div className="my-12">
      <Slider />
      <div className="container mt-4 lg:px-20 px-0 ">
        <div className="row">
          <div className="col-12 background">
            <h1 className="text-center mt-4  text-greens  text-4xl font-bold ">
              RESONITES OF INDORE STUDY CENTRE ARE NOW SHINING STARS OF VARIOUS
              IITS
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-center  px-3 my-5 gap-4 ">
        <img className="h-[32rem]" src={vedika_jaju} alt="vedika_jaju" />
        <div className="w-fit h-fit rounded-md border-2 border-black">
          <video
            className="w-[40vh] border-2 border-black  rounded-md"
            autoPlay
            loop
            controls
            muted
          >
            <source src={parth_bagle} type="video/mp4" />
          </video>
          {/* <div className="w-fit absolute bg-white p-5 text-3xl h-fit flex items-center buttom-0 justify-center rounded-full " >🔇</div> */}
        </div>
        <img className="h-[32rem]" src={mahi_mittal} alt="ved_jaju" />
      </div>
      <JEEResultSection />
      <div className="container mt-4 lg:px-24 px-0">
        <div className="row">
          <div className="col-12 backgrounds">
            <h1 className="text-center mt-4 pt-6 font-medium text-xl">
              <b>NEET UG Results</b>
              <br />
              Indore Resonites are shining in Govt Medical Colleges
            </h1>
          </div>
        </div>
      </div>
      <div className="container result mt-4 mb-4">
        <NEETResultSection />
      </div>
      <div className="d-flex justify-content-center"></div>
    </div>
  );
}

export default ResultPage;
