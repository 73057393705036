// Course Image
export { default as VIKAAS } from "../assets/image/course-card-img/VIKAAS (JA).webp";
export { default as VISHWAAS } from "../assets/image/course-card-img/VISHWAAS (JF).webp";
export { default as VIJAY } from "../assets/image/course-card-img/VIJAY (JR).webp";
export { default as SAKSHAM } from "../assets/image/course-card-img/SAKSHAM (MA).webp";
export { default as SAMBHAV } from "../assets/image/course-card-img/SAMBHAV (MF).webp";
export { default as SAMPOORN } from "../assets/image/course-card-img/sampoorn.jpeg";
export { default as PINNACLE } from "../assets/image/course-card-img/pinnacle.jpeg";
export { default as PARADISE } from "../assets/image/course-card-img/paradise.jpeg";
export { default as PIONEER } from "../assets/image/course-card-img/pioneer (1).jpeg";
export { default as tagline } from "../assets/Resonance-2[1].png";
export { default as atom } from "../assets/atom.png";
export { default as engiCap } from "../assets/cap.png";
export { default as science } from "../assets/science.png";
// Asset Image
export { default as Logo } from "../assets/logo.png.png";
export { default as ResonanceLogoBlack } from "../assets/Reso Logo Black.png";

export { default as Banner1 } from "../assets/image/carouselImages/Website Banner-2.jpg";
export { default as Banner2 } from "../assets/image/carouselImages/Website Banner-3.jpg";
export { default as Banner3 } from "../assets/image/carouselImages/Untitled-5.jpg";

export { default as LandingSliderImage1 } from "../assets/newImages/road.jpg";
export { default as LandingSliderImage2 } from "../assets/image/teachersDay.jpg";
export { default as LandingSliderImage3 } from "../assets/newImages/Spesial Self Study Rooms-1.jpg";
export { default as LandingSliderImage4 } from "../assets/newImages/student.jpg";
export { default as EarlyBirdOffer } from "../assets/offers_earlybird.jpg";
export {default as vedika_jaju} from "../assets/result/vedika jaju.jpg"
export {default as mahi_mittal} from "../assets/result/mahi_mittal.jpg"

export { default as bgTestForm } from "../assets/bgPracticeForm.jpg";

export {default as parth_bagle } from "../assets/testimonial_videos/parth_bagle.mp4";
