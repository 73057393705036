import React from "react";
import { EarlyBirdOffer } from "../data/ImageData";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Link } from "react-router-dom";

const EarlyBirdOfferSection = () => {
  return (
    <section className="my-8 rounded-md  p-6">
      <div className="flex lg:px-5 px-0 flex-col xl:flex-row lg:flex-row items-center gap-x-5">
        <div
          id="left_text"
          className="lg:w-[30%] xl:w-[30%] w-full flex gap-y-3 flex-col items-center"
        >
          <h2 className="text-3xl text-center font-semibold capitalize">
            get up to 90 % scholarship
          </h2>
          
            <div className="lg:flex xl:flex hidden justify-center items-center">
              <FlipClockCountdown
                to={new Date().getTime() + 12 * 3600 * 1000 + 5000}
                digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
               
              />
            </div>
          
          <div className="lg:hidden xl:hidden flex justify-center items-center">
            <FlipClockCountdown
              digitBlockStyle={{ width: 30, height: 40, fontSize: 20 }}
              to={new Date().getTime() + 12 * 3600 * 1000 + 5000}
            />
          </div>
          <h2 className="text-lg">Grab the offer before it's too late</h2>
          <h3 className="text-xl font-medium text-center">
            For More Details you can call Or visit our campus{" "}
          </h3>
          <div id="button-container " className="flex gap-x-2 items-center">
            <a href="tel:+917389934555">
              <button className="text-lg font-medium text-white px-3 py-2 bg-slate-900 rounded-lg ">
                Call Now
              </button>
            </a>
            <Link to="/#contact"><button className="text-lg font-medium text-white px-3 py-2 bg-slate-900 rounded-lg capitalize ">
              Address
            </button>
            </Link>
          </div>
        </div>
        <div className="h-fit w-auto  mx-auto my-4">
          <h2 className="text-center gradient-text  font-bold text-4xl capitalize">
            Early Bird offer
          </h2>
          <img
            className="rounded-xl border-4 border-black h-[350px] w-auto  mx-auto my-4"
            src={EarlyBirdOffer}
            alt="early bird offer"
          />
        </div>

        <div id="right_text lg:w-[30%] xl:w-[30%] w-full">
          <h2 className="text-3xl text-center tracking-wide font-semibold capitalize">
            <span className="font-extrabold gradient-text">Admission open</span>{" "}
            <br /> for 2025-2026 <br />
            For IIT-JEE | NEET 
          </h2>
          <h3 className="text-xl text-center lg:text-md mt-3">
            Enroll now ! Contact on below numbers
          </h3>
          <div className="my-4 flex justify-center ">
            <a className="text-xl font-medium mx-2" href="tel:+917389934553">
              7389934553
            </a>
            |
            <a className="text-xl font-medium mx-2" href="tel:+917389934553">
              7389934553
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EarlyBirdOfferSection;
