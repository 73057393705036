import React from "react";
import WhyImg from "../../src/assets/image/About resonance image 570X386.jpg";
import WhyImg2 from "../../src/assets/image/about 01.jpg";

const WhyResonanceInd = () => {
  return (
    <>
      <section className="mt-20 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-16">
        <div data-aos="zoom-out">
          <img src={WhyImg} alt="img" />
        </div>
        <div data-aos="fade-right" className=" flex flex-col gap-5">
          <div className="text-4xl font-semibold ">
            Resonance Indore - Best IIT JEE/NEET Coaching
          </div>
          <div data-aos="fade-right" className="flex flex-col mt-4 gap-5 ">
            <p>Only Expert, Experienced & Trained faculty member from KOTA.</p>
            <p>
              Individual attention of a contracted nature for each and every
              student.
            </p>
            <p>
              Every student gets extreme attention; Classes are restricted to a
              maximum of 40-45 students only.
            </p>
            <p>
              Individually motivated from time to time through counseling
              sessions.
            </p>
            <p>Every Teacher Plan the Course accordingly.</p>
            <p>
              Elevation to the advantage of the competition at all India level.
              .
            </p>
          </div>
        </div>
      </section>

      <div className="mt-20">
        <h3 data-aos="fade-left">
          Resonance IndoreInstitution of increasing repute in the country with
          strong focus on best practices in providing quality parallel education
          that prepares students for JEE-(Main + Advanced) and Pre – Medical.
          The Indore study centre is revolutionising the concept of preparative
          education, the teachers, the moral, the textbooks, the workers
          everyone’s efforts are directed towards obtaining the maximum from the
          student which is the most important factor in ensuring a student’s
          triumph in ever increasing competition of IIT-JEE/NEET.
        </h3>
        <br />
        <h3 data-aos="fade-left">
          In such a minimal duration, it has already established itself as the
          Institute of choice for preparation of bigger competitive exams like
          JEE (Main & Advanced) and Pre Medicals (NEET). It was felt that to
          give students an edge at younger ages, it was necessary for the
          children to be able to compete. So to enhance the academics of
          children from a younger age, launch of resonance’s pre – foundation
          career care program or pccp took place, and then the rest is history.
          There are arrays of performances, in both yearlong contact classroom
          program or yccp and pccp. Reso Indore is now also producing toppers in
          JEE-main or Advanced from Indore and giving Excellent Ratio of
          qualifiers for both IIT-JEE (Advanced) and NEET.
        </h3>
        <br />
        <h3 data-aos="fade-left">
          An exceptionally high amount of competition in academics can be found
          due to the enormous number of applicants that appear for the JEE (Main
          + Advanced) exams. Due to the emphasis placed on training as well as a
          comprehensive professional focus which sustains that high standards
          are maintained throughout the teaching, the Institute has made a name
          as one of the most desirable within the segment. Reso’s phenomenal
          growths in both quality and quantity of results speak volumes about
          our approach and the excellence which we impart. The fame has traveled
          beyond Indore and M.P, and students from distant places also come to
          enhance their skills and make their future. The Journey so far has
          been very thrilling and is evident from of Leaps and
          <strong> Bounds growth both in selections and enrolments.</strong>
        </h3>
      </div>

      <section className="grid grid-cols-1 ">
        <h1 className="text-3xl font-semibold mt-20 text-center ">
          Teaching Methodology at{" "}
          <span className="gradient-text">Resonance Indore </span>
        </h1>

        <p className="mt-10">
          At the center, teaching is considered to be an organized and well
          structured affair. Every aspect of learning such as the course content
          outline, marking scheme, lesson plan, supervision and the likes are
          alo clearly stipulated and adequately arranged. All systems work
          according to these rules and deliver a uniform and high-quality
          learning experience to all students
        </p>
      </section>

      <section className="mt-20 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="flex-1">
          <img className="w-[82%]" src={WhyImg2} alt="" />
        </div>
        <div className="flex-1 flex flex-col gap-3 ">
          <h2>
            Peer to Peer Mentoring of Students System Course & Lecture Planning
          </h2>
          <h2>Teaching Methodology</h2>
          <h2>Lecture Notes Preparation</h2>
          <h2>Making Lecture Notes</h2>
          <h2>Management of Faculty Efficiency</h2>
          <h2>Developing Study Material & Question Bank</h2>
          <h2>Preparing Test Papers</h2>
          <h2>Controlled Scheduling of Regular Classes</h2>
          <h2>Quality Control & Audit of Academic Works</h2>
          <h2>Academic Works – New Developments</h2>
          <h2>Days of Orientation & Motivation</h2>
        </div>
      </section>

      <div className="mt-20 text-center">
        <h1>
          * We are aware of the critical nature that different(training)
          components, tools, and techniques pose for results and so, we call
          this system “The RESO Way”
        </h1>
      </div>

      <section>
        <div className="mt-20 text-3xl font-semibold">
          Academic Team (Faculty)
        </div>
        <p className="mt-2">
          A teacher is a person who knows how to make you learn.
        </p>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:gap-0 md:gap-40">
          <div id="left" className="mt-14 flex flex-col gap-8">
            <div>
              Regular Classes (RCs)
              <br />
              Basic learning is achieved through more concentrated and better
              structured classroom environment.
            </div>

            <div>
              Daily Practice Problems (DPPs)
              <br />
              Daily Practice for enhancing what has been learnt on the same day.
            </div>

            <div>
              Practice Booklet (Sheets)
              <br />
              Experience & knowledge captured in an articulate manner.
              Increasing practice to get closer to perfection.
            </div>

            <div>
              Doubt Classes (DCs)
              <br />
              To provide all the necessary care and help in solving problems.
            </div>

            <div>
              Periodic Assessment Tests (PATS)
              <br />
              Evaluation is done through online && enviorment tests.
            </div>
          </div>

          <div id="right" className="mt-14 flex flex-col gap-8 ">
            <div>
              Academic Research & Development (R&D)
              <br />
              Creating new aids and questions for teaching.
            </div>

            <div>
              Academic Administration and Management
              <br />
              Overseeing all interaction concerning the process of delivering
              education.
            </div>

            <div>
              Online Learning Platform
              <br />
              Availability of Online Classes, Webinars & Recordings.
            </div>

            <div>
              Co-academic Arrangements
              <br />
              Quick assistance & smooth learning experience.
            </div>

            <div>
              Concept Building Classes.
              <br />
              Certain concepts and in-depth discussions on some important issues
              are covered after completing classXI and XII course.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyResonanceInd;
