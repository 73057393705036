import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import circle from "../assets/image/Why choose reso image 400X375 - Copy.jpg";
import { LandingSliderImage1 } from "../data/ImageData";
import * as Image from "../data/ImageData";
// import LandingSlider from "./Sliders/LandingSlider";
import { Link } from "react-router-dom";
import LandingSlider from "./Sliders/LandingSlider";

const Hero = () => {
  return (
    <div className=" px-6  sm:mt-20 lg:mt-2 heroBg lg:pt-28 lg:pb-32 flex flex-col-reverse lg:flex-row justify-center items-center">
      {/* Info Container */}
      <div id="info-container" className="lg:w-2/3">
        <h1
          data-aos="fade-right"
          data-aos-duration="1000"
          className="text-2xl sm:text-5xl lg:text-6xl font-bold  leading-[1.2!important]  font-poppins  "
        >
          India’s Most Trusted <br /> Education Platform <br />
           for IIT and NEET
        </h1>

        <p
          data-aos="fade-right"
          className="mt-6 text-slate-500 text-sm sm:text-base font-poppins "
        >
          Unlock your potential by signing up with Resonance Indore. <br />
          The most affordable learning solution.
        </p>

        <Link to="#course">
          <button
            data-aos="fade-right"
            data-aos-duration="1000"
            className="px-6 py-3 sm:py-4 mt-8 sm:mt-12 font-semibold bg-[#192F59] rounded-2xl text-white flex items-center gap-2 hover:bg-[#1a396e] font-poppins "
          >
            Get Started
            <FaArrowRightLong />
          </button>
        </Link>
      </div>

      {/* Image or Circle Element */}
      {/* <div data-aos="zoom-out-down"  data-aos-duration="700" >
      <div className="h-12 w-12 relative left-8 top-12 rounded-full rotate_3d " style={{background:`url(${Image.atom})`,backgroundSize:"cover",backgroundPosition:"center"}} ></div>
      <img className="w-72 h-72 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0" src={circle} /></div> */}
      <div data-aos="zoom-out-down" data-aos-duration="700">
        {/* <div
          className="h-12 w-12 relative left-8 top-12 rounded-full rotate_3d "
          style={{
            background: `url(${Image.atom})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div> */}
        {/* <img
          className="w-72 h-72 sm:w-80 sm:h-80 lg:w-[450px] lg:h-[450px] rounded-full bg-red-100 mb-10 lg:mb-0"
          src={LandingSliderImage1}
        /> */}
        <LandingSlider />
      </div>
    </div>
  );
};

export default Hero;
