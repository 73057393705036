import axios from "axios";
import React, { useState } from "react";
import { bgTestForm, ResonanceLogoBlack } from "../data/ImageData";
import { axiosClient } from "../webServices/axiosInstance";
import Notiflix from "notiflix";

const PracticeTestForm = () => {
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [schoolName, setSchoolName] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (name === null || mobile === null || schoolName === null) {
      return Notiflix.Notify.failure(
        "please fill all fields to get access of tests"
      );
    }
    Notiflix.Loading.dots();
    let enquiryData = {
      studentName: name,
      school: schoolName,
      mobile: mobile,
    };
    console.log(enquiryData);
    try {
      const response = await axiosClient().post(
        "/resosat/enquiry/send",
        enquiryData
      );
      if (response?.data?.status) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success(response?.data?.message);
        window.open("https://www.resosat.resonanceindore.com/practice-test");
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      Notiflix.Loading.remove();
      Notiflix.Notify.failure("Failed to Submit, Please try Again!");
      console.error(error);
    }
  };

  return (
    <div
      style={{ background: `url(${bgTestForm})` }}
      className="py-6 flex items-center h-screen"
    >
      <div className="bg-white gap-y-8 lg:w-6/12 w-11/12 m-auto border-2 flex flex-col p-10 ">
        <img className="w-52 mx-auto" src={ResonanceLogoBlack} alt="logo" />
        <p className="text-green-600 text-xl">
          *Fill below given form and get access to free practice tests.
        </p>
        <form onSubmit={handleRegister} className=" flex flex-col gap-1 ">
          <label className="p-1 font-semibold">Full Name</label>
          <input
            className=" rounded-xl border p-2"
            type="text"
            name="firstName"
            required
            placeholder="Enter Your Fullname"
            onChange={(e) => setName(e.target.value)}
            minLength={4}
          />

          <br />

          <label className="p-1 font-semibold">Mobile Number</label>
          <input
            className="rounded-xl border p-2"
            type="text"
            name="lastName"
            required
            placeholder="Mobile Number"
            onChange={(e) => setMobile(e.target.value)}
            pattern="^[6-9]\d{9}$"
          />

          <br />

          <label className="p-1 font-semibold">School Name</label>
          <input
            className="rounded-xl border p-2"
            type="text"
            name="lastName"
            required
            placeholder="*(Please Enter Full Name)"
            onChange={(e) => setSchoolName(e.target.value)}
            minLength={4}
          />
          <button
            type="submit"
            className="px-16 py-2 mt-10 bg-green-300 hover:bg-green-500 font-semibold"
          >
            Access Practice Test
          </button>
        </form>
      </div>
    </div>
  );
};

export default PracticeTestForm;
