import React, { Children, useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import Whatsapp from "./Whatsapp";
import ResoSatRegisterModal from "./ResoSatRegisterModal";
import moment from "moment";

const Layout = () => {
  const [showForm, setshowForm] = useState(false);
  const mainRef = useRef();
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "" || null) {
      mainRef.current.scrollIntoView({ block: "start" });
    }
  }, [location]);

  useEffect(() => {
    let popup = setTimeout(() => {
      setshowForm(true);
    }, 5000);
  }, []);

  return (
    <section >
      <Navbar />
      <Whatsapp />
      <main ref={mainRef} className="min-h-[60vh] max-w-screen overflow-x-hidden pt-4  lg:pt-28 lg:px-12 px-0">
        <Outlet />
      </main>
      <Footer />

      {showForm && (
        <div className="z-[1000] fixed w-screen h-screen top-0 backdrop-blur-sm bg-[#00000067]">
          <ResoSatRegisterModal setClose={setshowForm}/>
        </div>
      )}


      
      <div className="p-3 bottom-3 text-sm flex justify-center">
        Copyright © {moment().format('YYYY')}   MindCoders Pvt. Ltd. All rights reserved.
        </div>

    </section>

    
  );
};

export default Layout;
