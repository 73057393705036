import React from "react";

const TermsAndConditionPage = () => {
  return (
    <>
      <div className="lg:w-full w-full h-[35vh] gap-3 px-2  flex flex-col items-center justify-center bg-blue-900 text-white">
        <h1 className="text-center font-bold lg:text-4xl text-2xl ">
          Terms And Conditions
        </h1>
        <p className="text-center text-md font-normal">
          Please read this agreement carefully, as it contains important
          information regarding your legal rights and remedies.
        </p>
      </div>
      {/* ========================== policies section =================== */}
      <div className="p-4 lg:w-full w-11/12 mx-auto my-3 bg-slate-100">
        <p className="text-md">
          Welcome to the Resonance Indore website. By using this website, you
          agree to comply with the terms and conditions outlined here. These
          terms, along with our Privacy Policy, govern the relationship between
          Resonance Eduventures Limited (Resonance Network Partner "Enrich
          Education") and you. If you disagree with any part of these terms,
          please do not use our website.
        </p>
        <p className="text-md">
          The term{" "}
          <b>
            "Resonance Eduventures Limited" (Resonance Network Partner "Enrich
            Education"), "Us", or "We"
          </b>{" "}
          refers to the website owner. Our operational offices are located at:
        </p>
        <ul className="list-inside list-disc my-6">
          <li>
            <strong>South Tukoganj Campus - II</strong> Near South Avenue Hotel,
            Indore-452001
          </li>
          <li>
            <strong>Annapurna Campus - II</strong> 1st Floor, Meera’s Foothi
            Kothi Square, Sudama Nagar, Indore-452009.
          </li>
        </ul>
        <p className="text-md">
          The term <strong>"You"</strong> refers to the user or viewer of this
          website. By accessing and using this website, you acknowledge and
          agree to the following terms and conditions:
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">Usage Guidlines</h2>
        <p className="text-md mt-7">
          The content on this website is provided for your general information
          and personal use only. We reserve the right to modify or update the
          website content at any time without prior notice. By continuing to use
          the website, you agree to abide by all applicable laws and
          regulations.
          <br />
          <br />
          You may use this website only for lawful purposes. Any unauthorized,
          fraudulent, or malicious activities are strictly prohibited and may
          result in appropriate legal actions.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">Disclaimer</h2>
        <p className="text-md mt-7">
          We strive to ensure that the information on this website is accurate
          and up-to-date. However, we make no guarantees regarding the
          completeness, reliability, or suitability of the content for any
          particular purpose. Information on this website may contain errors or
          inaccuracies, and we disclaim liability for any such issues to the
          fullest extent permitted by law.
          <br />
          <br />
          Your use of any information or materials on this website is entirely
          at your own risk. It is your sole responsibility to verify that the
          services, products, or information provided meet your specific
          requirements. We shall not be held liable for any loss or damage
          resulting from the use of this website
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">
          Intellectual Property
        </h2>
        <p className="text-md mt-7">
          This website contains materials that are either owned by or licensed
          to us, including but not limited to the design, layout, graphics, and
          content. These materials are protected under copyright, trademark, and
          other intellectual property laws. Any reproduction, distribution, or
          unauthorized use of this content is strictly prohibited and may result
          in legal consequences.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">Trademarks</h2>
        <p className="text-md mt-7">
          All trademarks displayed on this website, which are not the property
          of or licensed to the operator, are acknowledged. You may not use any
          trademarks, logos, or branding elements displayed on this website
          without prior written permission, except as permitted by law.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">External Links</h2>
        <p className="text-md mt-7">
          This website may include links to other websites for your convenience
          and additional information. These links do not signify our endorsement
          of the linked websites, and we have no control over their content. We
          disclaim any responsibility for the accuracy or quality of the
          information on external websites.
          <br />
          If you decide to access any linked third-party websites, you do so at
          your own risk and subject to the terms and conditions of those
          websites.
        </p>
        <h2 className="font-bold pt-4 text-2xl uppercase ">Governing Law</h2>
        <p className="text-md mt-7">
          Your use of this website and any disputes arising from such use are
          governed by the laws of India. By using this website, you consent to
          the exclusive jurisdiction of the courts located in Indore, India, for
          any legal proceedings. <br />
          We encourage users to review these terms periodically to stay informed
          about their rights and obligations when using our website. If you have
          any questions or concerns regarding these terms, please contact us
          through the appropriate channels.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditionPage;
