import React from "react";

const PrivacyAndPolicies = () => {
  return (
    <>
      <div className="lg:w-full w-full h-[35vh] gap-3 px-2  flex flex-col items-center justify-center bg-blue-900 text-white">
        <h1 className="text-center font-bold lg:text-4xl text-2xl ">
          Privacy And Policy
        </h1>
        <p className="text-center text-md font-normal">
          Please read this agreement carefully, as it contains important
          information regarding your legal rights and remedies.
        </p>
      </div>
      {/* ========================== policies section =================== */}
      <div className="p-4 lg:w-full w-11/12 mx-auto my-3 bg-slate-100">
        <h2 className="font-bold pt-4 text-2xl Capitalize ">Privacy Policies</h2>
        <p className="text-md">
          Welcome to the Resonance Indore website. At{" "}
          <strong>
            Resonance Eduventures Limited (Resonance Network Partner "Enrich
            Education")
          </strong>
          , your privacy is of utmost importance to us. This Privacy Policy
          outlines how we collect, use, protect, and handle your personal
          information when you use our website. By accessing or using this
          website, you agree to the terms of this Privacy Policy.
        </p>
        <h2 className="font-bold pt-4 text-2xl Capitalize ">
          Information We Collect
        </h2>
        <p className="text-md">
          We may collect the following types of information:
        </p>

        <ul className="list-inside list-disc my-6">
          <li>
            <strong>Personal Information: </strong>Name, email address, phone
            number, and other contact details when you submit a form or contact
            us.
          </li>
          <li>
            <strong>Non-Personal Information:</strong> Browser type, IP address,
            geographical location, and other technical details collected
            automatically when you use our website.
          </li>
          <li>
            <strong> Cookies:</strong> Data stored on your device to enhance
            your user experience and improve website performance.
          </li>
        </ul>
        <h2 className="font-bold pt-4 text-2xl Capitalize ">
          How We Use Your Information
        </h2>
        <p className="text-md">
          We use the information we collect for the following purposes:
        </p>

        <ul className="list-inside list-disc my-6">
          <li>To provide, operate, and improve our website and services.</li>
          <li>To respond to your inquiries and provide customer support.</li>
          <li>
            To send promotional emails or notifications about our courses,
            events, or updates that may interest you.
          </li>
          <li>
            To analyze website usage patterns and improve user experience.
          </li>
        </ul>
        <h2 className="font-bold pt-4 text-2xl Capitalize">Data Security</h2>
        <p className="text-md ">
          We are committed to ensuring the security of your information. We
          implement appropriate technical and organizational measures to protect
          your data from unauthorized access, loss, misuse, or disclosure.
          However, no data transmission over the internet is entirely secure,
          and we cannot guarantee the absolute security of your information.
        </p>

        <h2 className="font-bold pt-4 text-2xl Capitalize">
          Sharing of Information
        </h2>
        <p className="text-md">
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except in the following
          circumstances:
        </p>
        <ul className="list-inside list-disc my-6">
          <li>
            To comply with legal obligations or respond to lawful requests from
            public authorities.
          </li>
          <li>To protect our rights, privacy, safety, or property.</li>
          <li>
            To trusted third-party service providers who assist us in operating
            our website, as long as they agree to keep your information
            confidential.
          </li>
        </ul>
        <h2 className="font-bold pt-4 text-2xl Capitalize">External Links</h2>
        <p className="text-md ">
          Our website may contain links to other websites. We are not
          responsible for the privacy practices or content of those external
          sites. We encourage you to review their privacy policies when visiting
          these websites.
        </p>
        <h2 className="font-bold pt-4 text-2xl Capitalize">Your rights</h2>
        <p>You have the following rights concerning your personal data:</p>
        <ul className="list-inside list-disc my-6">
          <li>To access, update, or delete your personal information</li>
          <li>To withdraw consent for data processing where applicable.</li>
          <li>
            To file a complaint with relevant authorities if you believe your
            privacy rights have been violated.
          </li>
          <br />
          <p className="text-md">
            To exercise these rights, please contact us using the information
            provided below.
          </p>
        </ul>
        <h2 className="font-bold text-2xl Capitalize pt-4">
          Updates to This Policy
        </h2>
        <p className="text-md">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. Any updates will be posted on
          this page with the revised date. We encourage you to review this page
          periodically to stay informed about how we protect your information.
        </p>
        <h2 className="font-bold pt-4 text-2xl Capitalize">Contact Us</h2>
        <p className="text-md">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <p>
          <strong>Resonance Eduventures Limited</strong> (Resonance Network
          Partner "Enrich Education")
        </p>
        <ul className="list-inside list-disc my-6">
          <li>
            <strong>South Tukoganj Campus - II</strong>, Near South Avenue
            Hotel, Indore-452001
          </li>
          <li>
            <strong>Annapurna Campus - II</strong>, 1st Floor, Meera’s Foothi
            Kothi Square, Sudama Nagar, Indore-452009
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyAndPolicies;
