import React from "react";
import logo from "../assets/image/Reso Logo Black.png";
import img1 from "../assets/whyRosonancePage-03.jpg";
import img2 from "../assets/whyRosonancePage-04.jpg";
import img3 from "../assets/whyRosonancePage-01.jpg";
import img4 from "../assets/whyRosonancePage-02.jpg";
import { tagline } from "../data/ImageData";

const WhyResonancePage = () => {
  return (
    <div>
      <section className="px-4 my-12 lg:px-0">
        <div className="flex flex-col sm:flex-row justify-center relative z-30 items-center gap-2 pt-4 sm:pt-16 text-center sm:text-left">
          <h1 className="font-bold text-xl sm:text-2xl">Why Choose</h1>
          <img src={logo} alt="Logo" className="h-10  sm:h-12 w-auto" />
        </div>

        <div className="flex justify-center items-center animate_float ">
          <img src={img1} className="h-72 w-auto" />
        </div>
      </section>

      <section
        data-aos="zoom-out-up"
        data-aos-duration={400}
        className="px-4 lg:px-28 "
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 text-center ">
          <div>
            <h3 className="font-bold text-lg mb-4">
              System-based Organization
            </h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md ">
              <li>
                Leadership: Experienced, Competent, Qualified (IITian) &
                Academician
              </li>
              <li>
                Core Team: Group of Academicians (Mostly IITians) & able
                Administrators
              </li>
              <li>Defined Processes and Structured Roles & Responsibilities</li>
              <li>Planned Academic Management & Curriculum Execution</li>
              <li>Student-centric & Performance Oriented Approach</li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Teaching Methodology</h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md">
              <li>
                All aims to answer the question: What guarantee does the student
                have? And the answer is simple,
              </li>
              <li>
                one of ensuring that all students reach the expected goals as
                defined before – Passed or Graduated.
              </li>
              <li>
                On a strategic level the guarantees are: Tried,tested and
                trusted teaching method which was developed and solidified in
                Kota region.
              </li>
              <li>
                Conceptual and application focused and result-oriented
                technologies including lessons addressed to ccp’s
              </li>
              <li>
                Based on fundamental learning and with solid and deep researched
                coverage of the syllabus in the offering
              </li>
              <li>
                Classes conducted at the beginning and during the program at the
                end of each module with the aim of increasing the individual
                level of understanding
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold text-lg mb-4">Learning Environment</h3>
            <ul className="list-disc list-inside space-y-2 text-left text-md">
              <li>
                Oriented course designs and effective execution of syllabi.
              </li>
              <li>
                Interactive approach for clarifying / resolving doubts and
                concepts
              </li>
              <li>
                Continuous Performance Evaluation & Remedial measures to improve
                performance
              </li>
              <li>
                Continuous performance appraisal and remedial measures to
                enhance performance. Continuous Competition Benchmarking
                Nationally.
              </li>
              <li>Co-academics makes learning supportive.</li>
            </ul>
          </div>
        </div>
      </section>
      <section
        data-aos="zoom-out-up"
        data-aos-duration={600}
        className="px-4 py-8 lg:px-24"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14">
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
              Preparatory Programmes for Various Examinations
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800">
              <li>Course planning & Syllabus Planning in disciplined way</li>
              <li>
                Promotion and solutions/clearing the concepts/doubts-
                interactive approach
              </li>
              <li>
                Continuous evaluation of performance, remedial measures to
                improvise
              </li>
              <li>Constant benchmarking at national levels</li>
              <li>
                The supportive co-academic atmosphere makes learning productive
              </li>
              <li>Preparatory Programmes for various exams</li>
              <li>Joint Entrance Examination JEE (MAIN + Advanced)</li>
              <li>Joint Entrance Examination JEE (MAIN)</li>
              <li>All India Pre-Medical Test (NEET (AIPMT))</li>
              <li>All India Institute Of Medical Science (AIIMS)</li>
              <li>
                Scholarship/IIT_JEE/NEET (along with JEE (Main + Advanced)/JEE
                (Main) & NEET/AIIMS respectivel )
              </li>
            </ul>
          </div>

          <div className="flex justify-center lg:justify-end">
            <img
              src={img2}
              alt="Preparatory Programmes"
              className=" animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>
        </div>
      </section>
      <section
        data-aos="zoom-out-up"
        data-aos-duration={1000}
        className="px-4 py-8 lg:px-24"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14 ">
          {/* Image Section */}
          <div className="flex justify-center lg:justify-start order-last md:order-first lg:order-first">
            <img
              src={img3}
              alt="Preparatory Programmes"
              className=" animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>

          {/* Text Section */}
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
              Student-centric Academic Programmes/Courses
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800 ">
              <li>
                Classroom Contact Programmes (CCPs): Year-long, Short-term &
                fast-track Programmes for JEE (Main + Advanced), JEE (Main) &
                NEET (AIPMT)/AIIMS for the students of Classes - XI, XII & XII+
              </li>
              <li>
                Pre-foundation Career Care Programmes (PCCPs) for NTSE &
                Olympiads for the students of Classes - V to X
              </li>
              <li>
                Distance Learning Programmes (DLPs) for the students of Classes
                - VI to XII & XII+ for JEE (Main + Advanced), JEE (Main), NEET
                (AIPMT)/AIIMS & NTSE etc.
              </li>
              <li>
                e-Learning Programmes (eLPs) for the students of Classes - V to
                XII & XII+ for JEE (Main + Advanced), JEE (Main), NEET
                (AIPMT)/AIIMS & NTSE etc.
              </li>
              <li>
                Coverage of respective class Board Syllabus (mostly CBSE based
                on NCERT) apart from the preparation of Competitive Examinations
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="px-4 py-8 lg:px-24">
        <div
          data-aos="fade-right"
          className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center lg:pt-14"
        >
          <div>
            <h1 className="text-xl sm:text-2xl font-bold mb-4">
              Student Welfare Programmes (SWPs)
            </h1>
            <ul className="list-disc list-inside space-y-2 text-gray-800 ">
              <li>Micro-Macro Level Performance Analysis & Communication</li>
              <li>
                Students Satisfaction Surveys (SSS) about Academic & Non-
                academics
              </li>
              <li>
                Parents-Teacher Meeting (PTM)/Parents-Resonance Meeting (PRM)
              </li>
              <li>
                Grievance Redressal, Assessment & Management System (GRAMS)
              </li>
              <li>
                Counseling (Career , Exams & Health) & Motivation Programmes
                (CMPs)
              </li>
            </ul>
          </div>

          <div className="flex justify-center lg:justify-end">
            <img
              src={img4}
              alt="Preparatory Programmes"
              className="animate_float w-full max-w-xs lg:max-w-md h-auto"
            />
          </div>
        </div>
      </section>

      <section className="px-4 py-8 lg:px-10">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-2 pt-4 sm:pt-16 text-center sm:text-left">
          <img src={tagline} alt="Logo" className="" />
        </div>
      </section>
    </div>
  );
};

export default WhyResonancePage;
