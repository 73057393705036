import React from "react";

const Card = ({ number, title }) => {
  return (
    <div data-aos="fade-in" className="w-72 h-40 bg-[#c4d2282a] text-center px-12 py-8 rounded-2xl border-4 border-[#C3D228]">
      <h1 className="font-bold mb-4 text-3xl">{number}</h1>
      <p className="text-slate-500">{title}</p>
    </div>
  );
};

const CardInsight = () => {
  return (
    <section data-aos="flip-left" className="container">
      <div className="py-10 my-10 flex justify-center flex-wrap gap-10">
        <Card
          number={"52539+"}
          title={"Students selected in IIT-JEE Advanced"}
        />

        <Card number={"257577+"} title={"Students selected in IIT-JEE Mains"} />

        <Card number={"22000+"} title={"Student selected in NEET"} />

        {/* <Card number={"105000+"} title={"Downloads done on Android app"} /> */}
      </div>
    </section>
  );
};

export default CardInsight;
