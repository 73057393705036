import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import {
  Home,
  CourseDetailPage,
  WhyResonance,
  ResonanceIndore,
  ResoSat,
  Result,
} from "./routes/elementRoutes";
import { routePath } from "./routes/path";
import Layout from "./components/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import TopNeetCoachingIndore from "./page/TopNeetCoachingIndore";
import PracticeTestForm from "./components/PracticeTestForm";
import TermsAndConditionPage from "./page/TermsAndConditionPage";
import PrivacyAndPolicies from "./page/PrivacyAndPolicies";

function App() {
  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePath.home} element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path={routePath.courseDetails}
            element={<CourseDetailPage />}
          />
          <Route path={routePath.whyResonance} element={<WhyResonance />} />
          <Route
            path={routePath.whyResonanceIndore}
            element={<ResonanceIndore />}
          />
          <Route path={routePath.resoSat} element={<ResoSat />} />
          <Route path={routePath.result} element={<Result />} />
          <Route path={routePath.topNeetIndore} element={<TopNeetCoachingIndore/>}/>
          <Route path={routePath.practiceTestForm} element={<PracticeTestForm/>}/>
          <Route path={routePath.termAndCondition} element={<TermsAndConditionPage/>}/>
          <Route path={routePath.privacyPolicy} element={<PrivacyAndPolicies/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
