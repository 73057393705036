import React, { useState } from "react";
import Logo from "../assets/image/Reso Logo White.png";
import { Link } from "react-router-dom";
import { routePath } from "../routes/path";
import { FaAngleDown } from "react-icons/fa6";
import MobileNav from "./MobileNav";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";

const navLinks = [
  { nav: "home", link: routePath.home },
  {
    nav: "About us",
    icon: <FaAngleDown />,
    subnav: [
      {
        nav: "Why Resonace Indore",
        link: routePath.whyResonanceIndore,
      },
      {
        nav: "Why Resonance",
        link: routePath.whyResonance,
      },
    ],
  },
  {
    nav: "Result",
    link: routePath.result,
  },
  {
    nav: "courses",
    icon: <FaAngleDown />,

    subnav: [
      {
        nav: "11th + 12th -JEE Main + Advance",
        link: "course/11th-course-jee",
      },
      {
        nav: "12th-JEE Main + Advanced",
        link: "course/12th-course-jee",
      },
      {
        nav: "Droppers-JEE Main + Advance",
        link: "course/Dropper-jee-mains-advance",
      },
      {
        nav: "11th+12th-NEET",
        link: "course/11th-neet",
      },
      {
        nav: "12th-NEET",
        link: "course/12th-neet",
      },
      {
        nav: "Droppers-NEET",
        link: "course/dropper-neet",
      },
      {
        nav: "Foundation-08th",
      },
      {
        nav: "Foundation-09th",
        link: "course/foundation-9th",
      },
      {
        nav: "Foundation-10th",
        link: "course/foundation-10th",
      },
    ],
  },
  {
    nav: "contact us",
    link: routePath.contactus,
  },
  {
    nav: "resosat",
    link: routePath.resoSat,
  },
  {
    nav: "resoTests",
    link: routePath.practiceTestForm,
  },
];

const Navbar = ({ set }) => {
  const [openabout, setOpenAbout] = useState("course");
  const [openMenu, setOpenMenu] = useState(false);
  const [resoTestForm, setResoTestForm] = useState(false);

  function handleAboutMenu(navigation) {
    if (navigation?.subnav) {
      setOpenAbout(navigation.nav);
      // setOpenCourse(false)
    } else {
      setOpenAbout(false);
    }
  }
  // function handleCourseMenu(navigation){
  //   if(navigation?.subnav&& navigation?.nav==="courses"){
  //     setOpenCourse(true)
  //     setOpenAbout(false)
  //   }else{
  //     setOpenCourse(false)
  //   }
  // }
  console.log(openabout);

  return (
    <div
      onMouseLeave={() => setOpenAbout(false)}
      className="w-full lg:sticky fixed top-0 z-[1000]"
    >
      <div className=" items-center gap-5 px-8 justify-between  py-2 lg:flex hidden bg-black text-white min-h-6">
        <div id="social_link_container" className="flex  items-center gap-x-4">
          <a href="https://www.instagram.com/resonance.indore/?utm_source=qr&igshid=YTlmZjI0ZWMzOA%3D%3D" target="_blank">
           <abbr title="Connect with Resonance Indore on Instagram"> <BsInstagram
              className="hover:text-red-500 duration cursor-pointer"
              size={20}
            />
            </abbr>
          </a>
          <a href="https://www.facebook.com/ResoIndore/" target="_blank">
           <abbr title="Connect with Resonance Indore on facebook"> <ImFacebook2
              className="hover:text-[dodgerblue] duration cursor-pointer"
              size={20}
            />
            </abbr>
          </a>
          <a href="https://www.facebook.com/ResoIndore/" target="_blank">
           <abbr title="Connect with Resonance Indore on twitter"> <FaXTwitter
              className="hover:text-[#208a43] duration cursor-pointer"
              size={20}
            />
            </abbr>
          </a>
        </div>
        <div className="flex items-center" id="contact_container">
          <div className="text-lg">
            South Tukoganj Center<span className="mx-2">»</span>
            <a className="hover:text-[blue!important]" href="tel:+917389934555">
              +917389934555
            </a>{" "}
            ,
            <a className="hover:text-[blue!important]" href="tel:+917389934553">
              +917389934553
            </a>
            <span className="mx-2">|</span>
          </div>
          <div className="text-lg ">
            Annapurna Center <span className="mx-2">»</span>
            <a className="hover:text-[blue!important]" href="tel:+917389934552">
              +917389934552
            </a>
            ,
            <a className="hover:text-[blue!important]" href="tel:+917389934555">
              +917389934555
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-between bg-[#0A557D] mt-0  h-20 lg:px-20 px-6 ">
        <div className=" flex items-center">
          <Link to="/">
            {" "}
            <img
              className="text-white lg:h-12 xl:h-10 md:h-12 h-10 "
              src={Logo}
              alt="LOGO"
            />{" "}
          </Link>
        </div>
        {/* -----------------------------------desktop nav bar------------------------------------------ */}
        <div className="hidden items-center md:hidden  lg:flex xl:flex justify-center gap-10 text-lg text-white">
          {navLinks.map((navigation, index) => (
            <div>
              <Link className="flex items-center gap-1" to={navigation.link}>
                <h4
                  onMouseEnter={() => {
                    handleAboutMenu(navigation);
                  }}
                  className="capitalize font-semibold"
                  key={index}
                >
                  {navigation.nav}
                </h4>
                {navigation?.icon && (
                  <span className="mt-[0.3rem]">{navigation.icon}</span>
                )}
              </Link>
              {/* ==================sub navigation menu===================== */}
              {navigation?.subnav && openabout === navigation?.nav && (
                <div className="absolute top-28 bg-white shadow-2xl px-4 py-3">
                  {navigation.subnav.map((item, index) => (
                    <Link to={item.link} key={index}>
                      <h2
                        onClick={() => setOpenAbout(false)}
                        className="text-black text-xl gradient-text-nav "
                      >
                        {item.nav}
                      </h2>
                    </Link>
                  ))}
                </div>
              )}
              {navigation?.subnav && openabout === navigation?.nav && (
                <div className="absolute top-28 bg-white shadow-2xl px-4 py-3">
                  {navigation.subnav.map((item, subIndex) => (
                    <Link to={item.link} key={subIndex}>
                      <h2
                        onClick={() => setOpenAbout(false)}
                        className="text-black text-xl "
                      >
                        {item.nav}
                      </h2>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}

          {/*          
            <h4>Contact Us</h4>
          </a>
          <h4>Results</h4>
          <h4>Courses</h4>
          <h4>ResoSAT</h4>
          <h4>Admission</h4> */}
        </div>

        {/* =======mobile navbar======== */}
        <MobileNav menuOpen={openMenu} setMenuOpen={setOpenMenu} />
        {/* =======mobile navbar======== */}

        {/* ---------------------------menu btn---------------------- */}
        <div
          className="flex lg:hidden justify-center items-center "
          onClick={() => setOpenMenu(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="white"
            class="size-9"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
            />
          </svg>
        </div>

        {/* ------------------------call now btn---------------------- */}
        {/* <div className="hidden lg:flex justify-center items-center">
          <a href="tel:+917389934555">
            <button className=" h-10 w-32 rounded-full bg-[#B5C800] flex justify-center  items-center gap-1">
              Call Now
              <i class="ri-phone-fill"></i>
            </button>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;
